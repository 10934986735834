/** @jsx jsx */
import { jsx, css } from "@emotion/core"

import { Link } from "gatsby"

import Layout from "../../components/Layout"
import Receipts from "../../components/pages/PixTutorial/Receipts"
import QrCode from "../../assets/images/svg/qrcode-pix.svg"
import KeyIcon from "../../assets/images/svg/key-pix.svg"
import GoBack from "../../assets/images/svg/go-back.svg"

import CopyToClipboard from "../../assets/images/svg/copy-to-clipboard.svg"

import { langNavigate } from "../../helpers"
import copyToClipboard from "./CopyToClipboard";

const Pix = ({ pageContext: translation}) => {

  return (
    <Layout translation={translation}>
      <section
        css={css`
          display: flex;
          flex-direction: column;
          padding: 0 20px 50px;

          text-align: center;
        `}
      >
        <header
          css={css`
            position: relative;
          `}
        >
          <Link
            href="/"
            css={css`
              position: absolute;
              left: 0;
            `}
          >
            <GoBack />
          </Link>

          <h2 style={{ color: "#203760", marginBottom: "43px" }}>
            Doe com o <strong style={{ color: "#00a1ff" }}>Pix</strong>
          </h2>
        </header>

        <KeyIcon
          css={css`
            display: block;
            margin: 0 auto 15px;
          `}
        />

        <h3
          css={css`
            color: #00a1ff;
            font-weight: 400;
            font-size: 20px;
            line-height: 38px;
            text-transform: none;
          `}
        >
          Confira as nossas chaves:
        </h3>

        <div
          css={css`
            color: #203760;
            font-weight: bold;
            font-size: 20px;
            line-height: 38px;

            p {
              display: flex;
              align-items: baseline;
              display: block;
              margin: 0 auto;

              svg {
                margin-left: 15px;
                width: 16px;
                height: 16px;
              }
            }
          `}
        >
          <p>
            doar@universal.org
            <CopyToClipboard
              onClick={() => {
                copyToClipboard("doar@universal.org")
              }}
            />
          </p>

          <p>
            29.744.778/0001-97
            <CopyToClipboard
              onClick={() => {
                copyToClipboard("29.744.778/0001-97")
              }}
            />
          </p>
        </div>

        <QrCode
          css={css`
            display: block;
            margin: 40px auto;
          `}
        />

        <p
          style={{
            width: "90%",
            margin: "20px auto",
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          *Para utilizar o QR Code, é necessário abrir a área do PIX dentro do
          aplicativo do seu banco e clicar na opção para Pagar com QR Code.
        </p>

        <button
          onClick={() => langNavigate(19, translation.currentLang)}
          css={css`
            background: #00a1ff;
            box-shadow: 0px 14px 24px rgba(0, 161, 255, 0.2);
            border-radius: 10px;
            color: #fff;
            text-transform: uppercase;
            padding: 10px 0;
            text-align: center;
            width: 100%;
            border: 0;
          `}
        >
          Não sabe como usar o Pix? <br /> Veja aqui
        </button>
        <Receipts></Receipts>
      </section>
    </Layout>
  )
}

export default Pix
